<template>
  <section id="portfolio">
    <div class="container">
      <div class="titleWrapper">
        <h2>Portfolio</h2>
        <div class="titleSeparator"></div>
      </div>
    </div>
    <div class="filters">
      <button class="filter" :class="{active: currentFilter === 'all'}" @click="setFilter('all')" aria-label="Afficher tous les éléments"><font-awesome-icon icon="fa-solid fa-asterisk" class="filtericon"/> Tous</button>
      <button class="filter" :class="{active: currentFilter === 'audiovisual'}" @click="setFilter('audiovisual')" aria-label="Afficher uniquement les éléments en catégorie audiovisuel"><font-awesome-icon icon="fa-solid fa-video" class="filtericon" /> Audiovisuel</button>
      <button class="filter" :class="{active: currentFilter === 'code'}" @click="setFilter('code')" aria-label="Afficher uniquement les éléments en catégorie code"><font-awesome-icon icon="fa-solid fa-code" class="filtericon"/> Code</button>
      <button class="filter" :class="{active: currentFilter === 'visuals'}" @click="setFilter('visuals')" aria-label="Afficher uniquement les éléments en catégorie création visuelle"><font-awesome-icon icon="fa-solid fa-paintbrush" class="filtericon" />  Création visuelle</button>
      <button class="filter" :class="{active: currentFilter === 'writing'}" @click="setFilter('writing')" aria-label="Afficher uniquement les éléments en catégorie écriture"><font-awesome-icon icon="fa-solid fa-feather-pointed" class="filtericon" />  Écriture</button>
      <button class="filter" :class="{active: currentFilter === 'ai'}" @click="setFilter('ai')" aria-label="Afficher uniquement les éléments en catégorie intelligence artificielle"><font-awesome-icon icon="fa-solid fa-microchip" class="filtericon" /> IA</button>
      <button class="filter" :class="{active: currentFilter === 'formatting'}" @click="setFilter('formatting')" aria-label="Afficher uniquement les éléments en catégorie mise en page "><font-awesome-icon icon="fa-solid fa-paragraph" class="filtericon" /> Mise en page</button>
      <button class="filter" :class="{active: currentFilter === 'music'}" @click="setFilter('music')" aria-label="Afficher uniquement les éléments en catégorie musique "><font-awesome-icon icon="fa-solid fa-music" class="filtericon" /> Musique</button>
    </div>
    <transition-group name="grid" tag="div" class="grid cards">
      <PortfolioElementCard v-for="el in sortByDate(filteredCards)" :key="el.id"
                            :title="el.title" :subtitle="el.subtitle"
                            :elementID="getID(el.id)" :link-destination="getLink(el.id)"
                            :category="el.category" />
    </transition-group>
  </section>
</template>

<script>
import PortfolioElementCard from "@/components/portfolio/PortfolioElementCard.vue";
import portfolio from "../../assets/json/portfolio.json"
import orderBy from 'lodash/orderBy'

export default {
  name: "PortfolioGrid",
  components: {
    PortfolioElementCard
  },
  data(){
    return{
      portfolio: portfolio,
      currentFilter: "all"
    }
  },
  methods: {
    getLink(id){
      return "#" + id
    },
    getID(id){
      return id + "card"
    },
    sortByDate(portfolio) {
      return orderBy(portfolio, 'date', 'desc')
    },
    setFilter(filter){
      this.currentFilter = filter;
    }
  },
  computed: {
    filteredCards(){
      if(this.currentFilter === "all"){
        return this.portfolio;
      } else {
        return this.portfolio.filter(card => card.category.includes(this.currentFilter));
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  section{
    position: relative;
  }

  .filters{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 70px;
  }

  .filters button{
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
    text-decoration: none;
    padding: 0 12px;
    min-width: 70px;
    height: 34px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    color: $dark;
    display: inline-block;
    border: 1px solid $dark;
    overflow: hidden;
    position: relative;
    z-index: auto;
    margin: 10px 10px 0 10px;
    user-select: none;
  }

  .filtericon{
    margin-right: 0.2rem;
  }

  .filters button:hover{
    border-color: $mainAccentColor;
  }

  .filters button.active{
    color: #ffffff;
    background-color: $mainAccentColor;
    border-color: $mainAccentColor;
  }

  .cards {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, $cardSize);
    grid-template-rows: repeat(auto-fill, $cardSize);
    grid-gap: 2px;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 15px 90px 15px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .grid-move,
  .grid-enter-active,
  .grid-leave-active {
    transition: all 0.5s ease;
  }

  .grid-enter-from{
    transform: translateY(30px);
  }

  .grid-enter-from,
  .grid-leave-to {
    opacity: 0;
  }

  .grid-leave-to{
    display: none;
  }

  #selection{
    position: relative;
  }


  @media screen and (max-width: 1220px) {

    .cards {
      width: 925px;
    }

  }

  @media screen and (max-width: 960px) {

    .cards{
      width: 100%;
    }

  }


  @media screen and (max-width: 768px) {

    .cards {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto auto;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 15px 90px;
    }

  }

  @media screen and (max-width: 480px){

    .cards{
      padding-bottom: 75px;
    }

  }


</style>