<template>
  <main id="home">
    <AboutMe/>
    <hr>
    <PortfolioGrid/>
    <hr>
    <PortfolioAll/>
  </main>
</template>

<script>
import AboutMe from "@/components/AboutMe";
import PortfolioAll from "@/components/portfolio/PortfolioAll.vue";
import PortfolioGrid from "@/components/portfolio/PortfolioGrid.vue";

export default {
  name: "MainPage",
  components: {
    AboutMe,
    PortfolioAll,
    PortfolioGrid
  },
}
</script>

<style lang="scss" scoped>

</style>