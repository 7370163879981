<template>
  <a :title="name" :href="link" :id="id+'HeaderLink'" class="socialMedia" target="_blank">
    <font-awesome-icon :icon="'fa-brands fa-'+icon" />
  </a>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SocialLink",
  components: {FontAwesomeIcon},
  props: {
    id : String,
    name : String,
    icon : String,
    link : String,
    color : String
  }
}
</script>

<style lang="scss" scoped>

  .socialMedia{
    position: relative;
    color: $dark;
    transition-duration: 150ms;
    font-size: 20px;
    padding-left: 30px;
  }

</style>