<template>
  <div class="buttonWrapper">
    <a :href="href" class="button" target="_blank">
      <font-awesome-icon icon="fa-solid fa-download" v-if="download === true" class="button__icon" />
      <font-awesome-icon icon="fa-brands fa-bandcamp" v-if="bandcamp === true" class="button__icon" />
      <font-awesome-icon icon="fa-brands fa-youtube" v-if="youtube === true"  class="button__icon" />
      <font-awesome-icon icon="fa-brands fa-github" v-if="github === true"  class="button__icon" />
      {{ text }}
    </a>
  </div>
</template>

<script>
export default {
  name: "BigButton",
  props: {
    text: String,
    href: String,
    download : {type: Boolean, default: false},
    bandcamp : {type: Boolean, default: false},
    youtube : {type: Boolean, default: false},
    github : {type: Boolean, default: false}
  }
}

</script>

<style lang="scss" scoped>

  .buttonWrapper{
    display: flex;
    justify-content: flex-end;
    text-align: center;
    margin-bottom: 15px;
  }

  .buttonWrapper--left{
    justify-content: flex-start;

    .button{
      margin-right: 0;
    }
  }

  .buttonWrapper--both{
    justify-content: center;

    div:nth-child(1){
      width: 50%;
      padding-right: 10px;
      display: flex;
      justify-content: flex-end;
    }

    .div:nth-child(2){
      width: 50%;
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;
    }

  }

  .button{
    text-decoration: none;
    padding: 10px 18px;
    min-width: 70px;
    height: 39px;
    font-size: 18px;
    line-height: 2.4rem;
    font-weight: 600;
    color: $mainAccentColor;
    border-radius: 2px;
    display: inline-block;
    border: 2px solid $mainAccentColor;
    overflow: hidden;
    position: relative;
    transition: color .55s;
    z-index: auto;
    margin-top: 20px;
    user-select: none;

    .button__icon{
      font-size: 22px;
      transform: translateY(2px);
      margin-right: 0.3rem;
    }
  }

  .button:before{
    content: "";
    position: absolute;
    z-index: -1;
    background: $mainAccentColor;
    height: 300px;
    width: 400px;
    border-radius: 20%;
    top: 0;
    left: -200%;
    transition: all .55s;
  }

  @media screen and (hover: hover) {

    .button:hover:before{
      top: -30px;
      left: -50px;
    }

    .button:hover{
      color: $white;
    }

  }

  @media screen and (hover: none) {

    .button{
      color: $white;
    }

    .button:before{
      top: -30px;
      left: -50px;
    }

  }

</style>