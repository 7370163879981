<template>
  <header>
    <div class="container">
      <div id="header__leftWrapper">
        <router-link :to="{path: '/', hash:'#home'}" id="mainLogoWrapper">
          <img src="../assets/icons/logoaxeldonared.svg" alt="" id="mainLogo--hover">
          <img src="../assets/icons/logoaxeldonablack.svg" alt="Logo typographique écrivant 'Axel Dona'" id="mainLogo">
        </router-link>
        <div id="menuIconWrapper" aria-label="Ouvrir/fermer menu" @click="toggleMenu">
          <div id="menuIcon" title="Menu">
            <span class="menuIcon__line1"></span>
            <span class="menuIcon__line2"></span>
            <span class="menuIcon--line3"></span>
          </div>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <router-link :to="{path:'/', hash:'#about'}" @click="toggleMenu">
              À propos de moi
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/', hash:'#portfolio'}" @click="toggleMenu">
              Portfolio
            </router-link>
          </li>
        </ul>
      </nav>
      <div id="header__rightWrapper">
        <SocialLink v-for="el in socialMedia" :key="el.id" :id="el.id" :name="el.name"
                    :icon="el.icon" :link="el.link" :color="el.color"
        />
      </div>
    </div>
  </header>
</template>

<script>
import SocialLink from "@/components/SocialLink";
import socialMedia from "../assets/json/socialMedia.json"

export default {
  name: "MainHeader",
  components: {
    SocialLink
  },
  data(){
    return{
      socialMedia: socialMedia
    }
  },
  mounted() {
    let menu = false;
    const menuIconWrapper = document.querySelector("#menuIconWrapper");
    const menuIcon = document.querySelector("#menuIcon");
    const nav = document.querySelector("nav");
    const headerRight = document.querySelector("#header__rightWrapper");
    const header = document.querySelector("header");

    function toggleMenu() {
      menuIcon.classList.toggle("active");
      if (!menu) {
        nav.style.height = "135px";
        headerRight.style.height = "60px";
        header.style.borderBottomColor = "#1E3050";
      } else {
        nav.style.height = "0";
        headerRight.style.height = "0";
        header.style.borderBottomColor = "#ddd";
      }
      menu = !menu;
    }

    function checkWidth() {
      if (window.innerWidth <= 760) {
        menuIconWrapper.addEventListener("click", toggleMenu);
      } else {
        menuIconWrapper.removeEventListener("click", toggleMenu);
        nav.style.height = "auto";
        headerRight.style.height = "auto";
        menuIcon.classList.remove("active");
        menu = false;
      }
    }

    checkWidth();

    window.addEventListener("resize", checkWidth);

    const links = document.querySelectorAll("nav a, #header__rightWrapper a");

    links.forEach((link) => {
      link.addEventListener("click", () => {
        if (window.innerWidth < 760) {
          toggleMenu();
        }
      });
    });
  },
};
</script>


<style lang="scss" scoped>

header {
  width: 100%;
  height: 90px;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  background-color: $white;
  border-bottom: 1px solid $backgroundSecondaryColor;
  display: inline-block;
  z-index: 900;
  opacity: 1;

  .container {
    justify-content: space-between;
    position: relative;
    z-index: 1000;
  }
}

#header__leftWrapper {
  width: 250px;
  display: flex;
  justify-content: flex-start;
  z-index: 1000;
}

#mainLogoWrapper {
  position: relative;
}

#mainLogo {
  height: 52px;
  margin-top: 19px;
  transition-duration: 600ms;
}

#mainLogo--hover {
  position: absolute;
  top: 19px;
  left: 0;
  height: 52px;
  z-index: 1500;
  opacity: 0;
  transition-duration: 600ms;
}

#mainLogoWrapper:hover #mainLogo {
  opacity: 0;
}

#mainLogoWrapper:hover #mainLogo--hover {
  opacity: 1;
}

#menuIconWrapper {
  display: none;
}

nav {
  text-align: center;
  height: 90px;
}

nav ul {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  cursor: default;
}

nav ul li {
  color: #222;
  display: inline-block;
  width: 150px;
  height: 60px;
  line-height: 60px;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
  vertical-align: middle;
}

nav ul li a {
  font-size: 15px;
  font-weight: 600;
  color: #222;
  display: inline;
  padding: 10px;
}

@media screen and (hover: hover) and (min-width: 961px) {

  nav ul li a {
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  nav ul li a:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px;
    left: 0;
    bottom: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    background-color: $mainAccentColor;
  }

  nav ul li a:hover {
    outline: 0;
    cursor: pointer;
    color: $mainAccentColor;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }

  nav ul li a:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%;
    left: 0;
    bottom: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    background-color: $mainAccentColor;
  }

}

@keyframes menu {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 100px;
    opacity: 1;
  }
}

#header__rightWrapper {
  width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}


@media screen and (max-width: 1220px) {

  header .container {
    width: 925px;
  }

  #header__leftWrapper {
    width: 175px;
  }

  #header__rightWrapper {
    width: auto;
  }

  nav ul li {
    width: 150px;
  }

}

@media screen and (max-width: 960px) {
  header {
    height: auto;
    background-color: $mainBackgroundColor;
    border-bottom: 1px solid $backgroundSecondaryColor;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;

    .container {
      flex-wrap: wrap;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;

      #header__leftWrapper {
        width: 50%;
        align-items: center;
        padding-top: 15px;

        #mainLogo {
          display: none;
        }

        #mainLogo--hover {
          opacity: 1;
          position: static;
          height: 52px;
        }
      }

      nav {
        order: 2;
        width: 100%;
        height: auto;

        ul {
          width: 100%;
          box-sizing: border-box;

          li {
            margin: 0;
            width: 24%;
            padding: 0;
            line-height: 40px;

            a {
              color: #222222;
              display: block;
              text-decoration: none;
            }
          }
        }
      }

      #header__rightWrapper {
        width: 50%;
        padding-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  header {
    background-color: $mainBackgroundColor;
    height: auto;
    border-bottom: 1px solid $backgroundSecondaryColor;

    .container {
      flex-wrap: nowrap;
      flex-direction: column;
      padding-right: 15px;

      #header__leftWrapper {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        height: 35px;

        #mainLogoWrapper {
          height: 35px;

          #mainLogo {
            display: none;
            height: 35px;
          }

          #mainLogo--hover {
            opacity: 1;
            position: static;
            height: 35px;
          }
        }

        #menuIconWrapper {
          display: inline-block;
          cursor: pointer;
          padding: 20px;

          #menuIcon {
            width: 2em;
            height: 1.1em;
            position: relative;
            display: block;
            margin: auto;
            cursor: pointer;

            span {
              font-size: initial;
              font-weight: initial;
              line-height: initial;
            }

            .menuIcon__line1 {
              @include menuIconLine;
              top: 0;
            }
            .menuIcon__line2 {
              @include menuIconLine;
              top: 50%;
            }
            .menuIcon--line3 {
              @include menuIconLine;
              top: 100%;
            }
          }

          #menuIcon.active .menuIcon__line1 {
            transform: translateY(0.55em) translateX(0) rotate(45deg);
            background: $backgroundSecondaryColor;
          }

          #menuIcon.active .menuIcon__line2 {
            opacity: 0;
            background: $backgroundSecondaryColor;
          }

          #menuIcon.active .menuIcon--line3 {
            transform: translateY(-0.55em) translateX(0) rotate(-45deg);
            background: $backgroundSecondaryColor;
          }

        }

      }

      nav {
        height: 0;
        overflow: hidden;
        transition-duration: 500ms;
        order: 2;
        width: auto;

        ul li {
          display: block;
          text-align: left;
          border-bottom: 1px solid $backgroundSecondaryColor;
          width: 100%;
          margin: 0;
          padding: 0;

          a {
            color: $textColor;
            display: block;
            position: relative;
            padding-left: 0;

            :active {
              color: $mainAccentColor;
            }
          }
        }
      }


      #header__rightWrapper {
        height: 0;
        overflow: hidden;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        order: 3;
        padding: 0;
        transition-duration: 200ms;

        .socialMedia {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {

}

</style>