<template>
  <MainHeader/>
  <router-view></router-view>
  <MainFooter/>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";

export default {
  name: 'App',
  components: {
    MainFooter,
    MainHeader
  }
}
</script>

<style lang="scss">

  *{
    margin: 0;
    padding: 0;
  }

  html{
    overflow-x: hidden;
  }

  body{
    font-family: $mainBodyFont;
    overflow-y: hidden;
    width: 100%;
  }

  main{
    position: relative;
    min-height: 100vh;
    width: 100%;
  }

  section{
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1920px;
  }

  .container{
    @include container;
  }

  h1{
    @include titles;
    font-size: 46px;
    font-weight: 500;
    text-align: center;
  }

  h2{
    @include titles;
    font-size: 36px;
    font-weight: 500;
  }

  h4{
    @include titles;
    font-size: 18px;
    font-weight: 200;
    margin-top: 5px;
  }

  h5{
    @include titles;
    font-size: 14px;
    font-weight: 300;
    margin-top: 7px;
    text-align: right;
  }

  p{
    font-size: 14px;
    font-weight: 300;
    color: $textColor;
    line-height: 1.4rem;
  }

  a{
    color: $mainAccentColor;
    text-decoration: none;
    font-weight: 600;
  }

  a:hover{
    text-decoration: underline;
  }

  @media screen and (hover: none) {
    a{
      text-decoration: underline;
    }
  }

  span{
    font-size: 14px;
    font-weight: 400;
    color: $textColor;
    line-height: 1.4rem;
  }

  hr{
    height: 0;
    border: 0;
    border-top: 1px solid $backgroundSecondaryColor;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .titleSeparator{
    height: 2px;
    background-color: $mainAccentColor;
    width: 140px;
    margin: 20px 0;
  }

  .textWrapper{
    @include textWrapper;
  }

  .textWrapper--right{
    padding-left: 100px;

    p{
      margin-bottom: 15px;
    }
  }

  .textWrapper--left{
    padding-right: 100px;

    p{
      margin-bottom: 15px;
    }
  }

  .textWrapper--center{

    h2{
      padding: 0 50px;
      text-align: center;
    }

    .titleSeparator{
      margin-left: auto;
      margin-right: auto;
    }

    p{
      margin-bottom: 15px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: justify;
    }
  }


  .titleWrapper{
    width: 100%;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    .titleSeparator{
      margin-left: auto;
      margin-right: auto;
    }

    p{
      margin-bottom: 15px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
    }
  }

  .imgWrapper{
    width: 50%;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .imgWrapper--left{
    padding: 90px 90px 90px 0;
  }

  .imgWrapper--right{
    padding: 90px 0 90px 90px;
  }

  .noselect{
    user-select: none;
  }

  @media screen and (max-width: 1220px) {

    .container{
      width: 925px;
    }

    .textWrapper{
      padding-bottom: 75px;
      padding-top: 75px;
    }

    .textWrapper--right{
      padding-left: 70px;
    }

    .textWrapper--left{
      padding-right: 70px;
    }
  }


  @media screen and (max-width: 960px) {


    .container {
      width: 100%;
    }


    .textWrapper {
      padding-top: 50px;
    }

    .textWrapper--right,
    .textWrapper--left{
      padding: 50px;
    }

    .titleWrapper {
      width: 90%;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  @media screen and (max-width: 768px){

  }

  @media screen and (max-width: 480px){

    .textWrapper {
      width: 100%;

      h2 {
        padding: 0;
        margin: 0 auto;
      }
    }

    .textWrapper--right,
    .textWrapper--left{
      padding-right: 3%;
      padding-left: 3%;
    }

    .titleWrapper {
      width: 90%;
    }

  }

</style>
