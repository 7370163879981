<template>
  <section :id="elementID" class="pfElement">
    <div class="container container--pf">
      <div class="imgWrapper imgWrapper--left imgWrapper--pf" :class="horizontal ? 'pfElement__itemWrapper--horizontal' : ''" >
        <div class="pfElement__item"
             :class="[
                video ? 'pfElement__item--video' : '',
                vertical ? 'pfElement__item--vertical' : '',
                double ? 'pfElement__item--double' : '',
                horizontal ? 'pfElement__item--horizontal' : ''
             ]">
          <a v-if="!video" :href="double ? '/img/fullres/'+elementID+'-1'+fileType : fileType==='.pdf' ? '/pdf/'+ elementID+fileType :  '/img/fullres/'+elementID+fileType" target="_blank" :id="elementID+'-link'">
            <img :src="double ? require('../../assets/img/display/'+elementID+'-1-900.webp') : require('../../assets/img/display/'+elementID+'-900.webp')" :alt="alt">
            <div>
              <span>Afficher</span>
            </div>
          </a>
          <video v-if="video" controls>
            <source :src="require(`../../assets/video/${elementID}.${videoType}`)" :type="'video/'+videoType">
          </video>
        </div>
        <div v-if="double" class="pfElement__item"
             :class="[
                video ? 'pfElement__item--video' : '',
                vertical ? 'pfElement__item--vertical' : '',
                double ? 'pfElement__item--double' : '',
                horizontal ? 'pfElement__item--horizontal' : ''
             ]">
          <a :href="'img/fullres/'+elementID+'-2'+fileType" target="_blank" :id="elementID+'-link2'">
            <img :src="require('../../assets/img/display/'+elementID+'-2-900.webp')" :alt="alt2">
            <div>
              <span>Agrandir</span>
            </div>
          </a>
        </div>
      </div>
      <div class="textWrapper textWrapper--right">
        <h2>{{title}}</h2>
        <h4>{{subtitle}}</h4>
        <div class="titleSeparator"></div>
        <p v-if="p1" v-html="p1"></p>
        <p v-if="p2" v-html="p2"></p>
        <hr v-if="credits">
        <p v-if="credits" v-html="credits" class="pfElement__credits"></p>
        <BigButton v-if="button" :href="link" :text="youtube ? 'Visionner ' + title : github ? title + ' sur GitHub' : 'Écouter ' + title" :youtube="youtube" :bandcamp="bandcamp" :github="github"/>
      </div>
    </div>
    <div class="pfElement__menuWrapper">
      <div class="pfElement__menu">
        <router-link :to="{path:'/', hash:'#portfolio'}" title="Retour à la grille"><font-awesome-icon icon="fa-solid fa-grip"/></router-link>
        <router-link v-if="portfolio[no-1]" :to="{path:'/', hash:getPreviousID}" :title="getPreviousTitle"><font-awesome-icon icon="fa-solid fa-circle-arrow-up"/></router-link>
        <router-link v-if="portfolio[no+1]" :to="{path:'/', hash:getNextID}" :title="getNextTitle"><font-awesome-icon icon="fa-solid fa-circle-arrow-down"/></router-link>
      </div>
    </div>
  </section>
  <hr>
</template>

<script>
import 'vue-lite-youtube-embed/style.css'
import BigButton from "@/components/BigButton.vue";

export default {
  name: "PortfolioElementDisplay",
  components: {
    BigButton
  },
  props: {
    elementID : String,
    title : String,
    subtitle : String,
    category : String,
    date : String,
    no : Number,
    portfolio : Array,
    p1 : String,
    p2 : String,
    double : {type: Boolean, default: false},
    video : {type: Boolean, default: false},
    vertical : {type: Boolean, default: false},
    horizontal : {type: Boolean, default: false},
    fileType : {type: String, default: '.png'},
    videoType : {type: String, default: 'mp4'},
    link : String,
    alt: {type: String, default: ""},
    alt2: {type: String, default: ""},
    button : Boolean,
    youtube : {type: Boolean, default: false},
    bandcamp : {type: Boolean, default: false},
    github: {type: Boolean, default: false},
    credits: String
  },
  computed: {
    getPreviousID(){
      if (this.portfolio[this.no-1].id){
        return "#" + this.portfolio[this.no-1].id
      } else {
        return "#"
      }
    },
    getNextID(){
      if (this.portfolio[this.no+1].id){
        return "#" + this.portfolio[this.no+1].id
      } else {
        return "#"
      }
    },
    getPreviousTitle(){
      if (this.portfolio[this.no-1].title){
        return this.portfolio[this.no-1].title
      } else {
        return ""
      }
    },
    getNextTitle(){
      if (this.portfolio[this.no+1].title){
        return this.portfolio[this.no+1].title
      } else {
        return ""
      }
    }
  }
}
</script>


<style lang="scss" scoped>


  .pfElement{
    position: relative;
  }

  .container--pf{
    min-height: calc(100vh - 90px);
  }

  .container--pf .textWrapper--right hr{
    margin-bottom: 1rem;
    margin-right: 15px;
  }

  .imgWrapper--pf{
    display: flex;
    justify-content: space-between;
  }

  .pfElement__item {
    width: 100%;
    background-color: $mainBackgroundColor;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  #itemtarot1,
  #itemtarot2{
    width: 45%;
  }

  .pfElement__item a {
    width: 64vh;
    height: 64vh;
    position: relative;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    border: 1px solid $white;
  }
  .pfElement__item a > img {
    display: block;
    margin: 0 auto;
    border: none;
    padding: 0;
    box-sizing: border-box;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition-property: opacity, transform;
    transition-duration: 0.4s;
  }

  .pfElement__item--vertical a > img {
    height: 64vh;
  }

  .pfElement__item a > div {
    box-sizing: border-box;
    position: absolute;
    width: 94%;
    height: 94%;
    top: 3%;
    left: 3%;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transform: scale3d(1.5, 1.5, 1);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition-property: visibility, opacity, transform;
    transition-duration: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pfElement__item a i {
    color: $white;
    font-size: 6rem;
    display: block;
    opacity: .8;
    margin-bottom: 20px;
  }

  .pfElement__item .pfElement__item--video{
    max-width: 100%;
  }

  .pfElement__item a span {
    box-sizing: border-box;
    display: block;
    width: 40%;
    margin: auto auto;
    text-transform: uppercase;
    color: $white;
    font-size: 0.938rem;
    vertical-align: center;
  }

  @media (hover: hover) {

    .pfElement__item a:hover > img {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
      opacity: 0.7;
      transform: scale3d(1.5, 1.5, 1);
    }
    .pfElement__item a:hover > div {
      visibility: visible;
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }

    .pfElement__item a:hover #conteneuragrandir{
      width: 17%;
      height: 17%;
      transition-delay: 0.3s;
    }

  }

  .pfElement__menuWrapper{
    position: absolute;
    right: 0;
    width: calc((100% - 1170px)/2);
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 50;
  }

  .pfElement__menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pfElement__menu a{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 30px;
    color: $mainAccentColor;
  }

  .pfElement__menu a:nth-child(3){
    margin-bottom: 67px;
  }


  .pfElement__credits{
      font-style: italic;
  }

  .pfElement__item--video{
    height: 64vh;
  }

  .pfElement__item--vertical a{
    display: flex;
    width: min-content;
  }

  .pfElement__item--vertical a > img{
    width: auto;
  }

  .pfElement__item--double{
    width: 45%;
  }

  .pfElement__item--double a{
    display: flex;
    height: min-content;
  }

  .imgWrapper--pf .pfElement__item--double a > img{
    width: 100%;
    height: min-content;
    flex-grow: 0;
  }

  .imgWrapper--pf .pfElement__item--double:nth-child(even){
    align-self: flex-end;
  }

  .pfElement__item--horizontal{
    padding-right: 0;
  }

  .pfElement__item--horizontal > a{
    height: fit-content;
    width: 100%;
  }

  .pfElement__item--horizontal > a > img{
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: 1220px){

    .pfElement{
      display: flex;
      flex-direction: column;
    }

    .pfElement__menuWrapper{
      position: static;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 35px;
      width: 100%;
    }

    .pfElement__menu{
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }

    .pfElement__menu a{
      margin: 0 25px;
    }

    .pfElement__menu a:nth-child(3) {
      margin-bottom: 0;
    }

    .container--pf{
      flex-wrap: wrap;
    }

    .container--pf .imgWrapper--left{
      width: 66%;
      padding: 75px 0 0 0;
    }

    .container--pf .textWrapper--right{
      width: 33%;
      padding-left: 0;
      padding-bottom: 0;
    }

    .pfElement__item{
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 960px){

    .container--pf{
      flex-wrap: wrap;
    }

    .container--pf .imgWrapper--left{
      width: 100%;
    }

    .container--pf .textWrapper--right{
      width: 100%;
      padding-left: 50px;
    }

    .pfElement__item{
      justify-content: center;
    }

    .pfElement__item a{
      height: auto;
      width: auto;
    }

    .pfElement__item a > img{
      max-height: 60vh;
      max-width: 500px;
      height: auto;
    }

    .pfElement__item--horizontal a > img{
      max-width: 100%;
    }

    .pfElement__item--video{
      width: 100%;
      max-width: 480px;
      height: auto;

      video{
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container--pf .textWrapper--right {
      padding-left: 0;
    }

    .pfElement__item a > img{
      max-height: 60vh;
      max-width: 100%;
      height: auto;
    }
  }


</style>