<template>
  <section id="about">
    <div id="aboutLeftImg"></div>
    <div class="container">
      <div class="textWrapper textWrapper--right">
        <h2>À propos de moi</h2>
        <div class="titleSeparator"></div>
        <p>
          Je suis un futur ingénieur, touche-à-tout du numérique au parcours atypique, et actuellement étudiant à
          <a href="https://www.ingenieur-imac.fr/" target="_blank">l'IMAC</a>,
          la seule formation d'ingénieur publique fusionnant arts et sciences. J'ai atteint cette formation après des études de langues
          et une longue expérience à l’étranger très enrichissante. Ce parcours fait ma force et m’a permis de me former
          un large bagage de compétences qui me rend très polyvalent.
        </p>
        <p>
          J’adore mettre mes compétences techniques au service de la création numérique : je suis passionné par
          la création visuelle, le web, l’audiovisuel, la musique assistée par ordinateur, les jeux de rôle et la création
          par intelligence artificielle. Mon esprit curieux me pousse constamment à trouver une nouvelle manière de raconter une histoire.
        </p>
        <p>
          Je cherche toujours à approfondir mes compétences et explorer de nouveaux horizons. Si vous
          cherchez quelqu’un de passionné et polyvalent, n’hésitez pas à me contacter !
        </p>
        <BigButton text="Télécharger mon CV" href="https://axeldona.com/dona-axel_cv.pdf" download />
      </div>
    </div>
  </section>
</template>

<script>
import BigButton from "@/components/BigButton";

export default {
  name: "AboutMe",
  components: {
    BigButton
  }
}
</script>

<style lang="scss" scoped>

  #about{
    position: relative;
    z-index: 10;
    padding-top: 90px;
    overflow: hidden;

    #aboutLeftImg{
      position: absolute;
      width: 50%;
      float: left;
      z-index: -1;
      height: 100%;
      background-image: url(../assets/img/axel.webp);
      background-size: cover;
      background-position: 50% 25%;
    }

    .container{
      justify-content: flex-end;
    }
  }

  #about .textWrapper--right{
    position: relative;
    z-index: 12;
  }

  @media screen and (max-width: 960px){

    #about{

      #aboutLeftImg{
        width: 100%;
        float: none;
        background-size: 100%;
        background-position: 0 0;
      }

      .container{
        justify-content: flex-start;
        align-items: flex-end;
        height: 768px;
      }

      .textWrapper--right{
        width: 100%;
      }

      p, h2{
        color: white;
      }

      h2{
        text-shadow: $titleColor 15px 0 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {

    #about{
      display: flex;
      flex-direction: column;

      #aboutLeftImg{
        position: static;
        height: 340px;
        background-size: cover;
        background-position: 0 15%;
        order: 2;
      }

      .container{
        justify-content: flex-start;
        align-items: flex-end;
        height: auto;

        .textWrapper--right{
          padding-top: 30px;

          h2{
            color: $titleColor;
            text-shadow: none;
          }

          p{
            color: $textColor;
          }
        }
      }
    }
  }

</style>