import { createWebHistory, createRouter } from "vue-router";
import MainPage from "../views/MainPage.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: MainPage,
    }
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to) {
        if (to.hash) {
            let headerHeight;
            if(window.innerWidth > 960){
                headerHeight = 90
            } else if (window.innerWidth > 768){
                headerHeight = 131
            } else {
                headerHeight= 55
            }
            return {
                el: to.hash,
                behavior: 'smooth',
                top: headerHeight
            }
        }
    },
    routes
});

export default router;
