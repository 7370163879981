<template>
  <footer>
    <div class="container">
      <ul>
        <li>Suivez-moi sur</li>
        <li v-for="el in socialMedia" :key="el.id">
          <a :href="el.link">{{el.name}}</a>
        </li>
      </ul>
      <span id="copyright">Copyright © Axel Dona 2023</span>
    </div>
  </footer>
</template>

<script>
import socialMedia from "../assets/json/socialMedia.json"

export default {
  name: "MainFooter",
  data(){
    return{
      socialMedia: socialMedia
    }
  }
}
</script>

<style lang="scss" scoped>

  footer{
    background-color: $dark;
    width: 100%;
    height: auto;
    padding-left: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    color: $white;

    .container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2rem 0;

      ul{

        li{
          display: inline-block;
          margin-right: 3rem;

          a{
            color: white;
            font-weight: 600;
          }
        }
      }
    }

    #copyright{
      display: block;
      margin-top: 3rem;
      color: $white;
    }

  }

  @media screen and (max-width : 768px){
    footer .container ul li{
      display: block;
      margin-right: 0;
      margin-top: 1rem;
    }
  }

</style>