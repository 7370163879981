<template>
  <PortfolioElementDisplay v-for="el in updateID(sortByDate(portfolio))" :key="el.id"
                           :title="el.title" :subtitle="el.subtitle" :p1="el.p1" :p2="el.p2"
                           :elementID="el.id" :date="el.date" :category="el.category"
                           :portfolio="updateID(sortByDate(portfolio))" :no="el.no"
                           :double="el.double" :video="el.video" :vertical="el.vertical"
                           :fileType="el.fileType" :videoType="el.videoType"
                           :horizontal="el.horizontal" :youtube="el.youtube" :bandcamp="el.bandcamp" :github="el.github"
                           :alt="el.alt" :alt2="el.alt2" :link="el.link" :button="el.button" :credits="el.credits"
  />
</template>

<script>
import portfolio from "@/assets/json/portfolio.json"
import orderBy from 'lodash/orderBy'
import PortfolioElementDisplay from "@/components/portfolio/PortfolioElementDisplay.vue";

export default {
  name: "PortfolioAll",
  components: {PortfolioElementDisplay},
  data(){
    return{
      portfolio: portfolio
    }
  },
  methods: {
    getID(id){
      return "#" + id
    },
    sortByDate(portfolio) {
      return orderBy(portfolio, 'date', 'desc');
    },
    updateID(portfolio) {
      for (let i = 0; i < portfolio.length;  i++ ){
        portfolio[i].no = i;
      }
      return portfolio
    }
  }
}
</script>

<style lang="scss" scoped>

</style>