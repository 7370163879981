<template>
  <router-link :to="{path: '/', hash:linkDestination}" @mouseover="hover = true" @mouseleave="hover = false" :class="{hovered : hover}" :aria-label="'Aller vers '+title">
    <div class="card" :id="elementID">
      <div class="card__categories">
        <font-awesome-icon v-for="cat in categories" :key="cat" :icon="getIcon(cat)" class="card__categoryIcon" />
      </div>
      <div class="card__content">
        <h2 class="card__title">{{ title }}</h2>
        <p class="card__subheading">{{ subtitle }}</p>
        <font-awesome-icon icon="fa-solid fa-chevron-down" class="card__bottomIcon"/>
      </div>
    </div>
  </router-link>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "PortfolioElementCard",
  components: {FontAwesomeIcon},
  props: {
    linkDestination : String,
    elementID : String,
    title : String,
    subtitle : String,
    category : String,
  },
  data(){
    return{
      hover : false
    };
  },
  computed: {
    categories(){
      return this.category.split(" ");
    },
    getIcon(){
      return (category) => {
        if (category === 'music'){
          return "fa-solid fa-music"
        } else if (category === 'audiovisual'){
          return "fa-solid fa-video"
        } else if (category === 'visuals'){
          return "fa-solid fa-paintbrush"
        } else if (category === 'code'){
          return "fa-solid fa-code"
        } else if (category === 'ai'){
          return "fa-solid fa-microchip"
        } else if (category === 'writing'){
          return "fa-solid fa-feather-pointed"
        } else if (category === 'formatting'){
          return "fa-solid fa-paragraph"
        } else {
          return "fa-solid fa-asterisk"
        }
      }
    }
  }
}

</script>

<style scoped>
</style>


<style lang="scss" scoped>

  a,a:hover{
    text-decoration: none;
    width: 100%;
  }

  .card {
    box-sizing: border-box;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    overflow: hidden;
    padding: 1rem;
    width: $cardSize;
    height: $cardSize;
    text-align: center;
    color: $white;
    background-color: $white;
  }
  .card:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 104%;
    background-size: cover;
    background-position: center 0;
    transition: transform calc(700ms * 1.5) cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: none;
  }

  .card:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%);
    transform: translateY(-50%);
    transition: transform calc(700ms * 2) cubic-bezier(0.19, 1, 0.22, 1);
  }

  .card__categories{
    position: absolute;
    top: 12px;
    right: 14px;
    z-index: 15;
    font-size: 12px;
    text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    display: flex;
    justify-content: right;
  }

  .card__categories .card__categoryIcon{
    margin-left: 0.5rem;
  }

  .card__content {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1;
  }


  .card h2.card__title {
    font-family: $mainTitleFont;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.4rem;
    line-height: 1.2;
    color: $white;
    text-shadow: 0 0 12px rgba(0, 0, 0, 1);
    text-decoration: none;
  }

  .card__subheading {
    font-family: $mainTitleFont;
    font-size: 13px;
    line-height: 1.35;
    color: $white;
    font-weight: 300;
    text-transform: uppercase;
  }

  .card__bottomIcon{
    font-size: 20px;
    padding-top: 4px;
  }

  .card__content > * + * {
    margin-top: 1rem;
  }

  .hovered .cartecategorie{
    opacity: 0;
    transition-duration: 400ms;
  }

  #animationimaccard h2.card__title{
    color: $black;
    text-shadow: 0 0 12px rgba(255, 255, 255, 1);
  }

  .hovered #animationimaccard h2.card__title{
    color:$white;
    text-shadow: 0 0 12px rgba(0, 0, 0, 1);
  }

  #wavescard:before{
    background-image: url("../../assets/img/card/waves-card.jpg");
  }

  #medusecard:before{
    background-image: url("../../assets/img/card/meduse-card.jpg");
  }

  #trounoircard:before{
    background-image: url("../../assets/img/card/trounoir-card.jpg");
  }

  #snydercutcard:before{
    background-image: url("../../assets/img/card/snydercut-card.jpg");
  }

  #issmapcard:before{
    background-image: url("../../assets/img/card/issmap-card.jpg");
  }

  #kissofdeathcard:before{
    background-image: url("../../assets/img/card/kissofdeath-card.jpg");
  }

  #billieswatchingcard:before{
    background-image: url("../../assets/img/card/billieswatching-card.jpg");
  }

  #primevalwaterscard:before{
    background-image: url("../../assets/img/card/primevalwaters-card.jpg");
  }

  #phantompaincard:before{
    background-image: url("../../assets/img/card/phantompain-card.jpg");
  }

  #ektokleptocard:before{
    background-image: url("../../assets/img/card/ektoklepto-card.jpg");
  }

  #meteovuecard:before{
    background-image: url("../../assets/img/card/meteovue-card.jpg");
  }

  #tarotcard:before{
    background-image: url("../../assets/img/card/tarot-card.jpg");
  }

  #animationimaccard:before{
    background-image: url("../../assets/img/card/animationimac-card.jpg");
  }

  #percontecard:before{
    background-image: url("../../assets/img/card/perconte-card.jpg");
  }

  #squadcard:before{
    background-image: url("../../assets/img/card/squad-card.jpg");
  }

  @media (hover: hover) and (min-width: 600px) {

    .card:after {
      transform: translateY(0);
    }

    .card__content{
      transform: translateY(calc(100% - 3rem));
    }

    .card__content > *:not(.card__title) {
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1), opacity 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .card:hover,
    .card:focus-within {
      align-items: center;
    }

    .card:hover:before,
    .card:focus-within:before {
      transform: translateY(-4%);
    }

    .card:hover:after,
    .card:focus-within:after {
      transform: translateY(-50%);
    }

    .card:hover .card__content,
    .card:focus-within .card__content {
      transform: translateY(0);
    }

    .card:hover .card__content > *:not(.card__title),
    .card:focus-within .card__content > *:not(.card__title) {
      opacity: 1;
      transform: translateY(0);
      transition-delay: calc(700ms / 8);
    }

    .card:focus-within:before, .card:focus-within:after,
    .card:focus-within .card__content,
    .card:focus-within .card__content > *:not(.card__title) {
      transition-duration: 0s;
    }

  }

  @media screen and (max-width: 768px) {

    .card{
      width: 180px;
      height: 180px;
    }

    .card__title {
      font-size: 14px;
      letter-spacing: 0.2rem;
      line-height: 1;
    }

  }

  @media screen and (max-width: 480px){

    .card{
      width: 50vw;
      height: 50vw;
      padding: 0;
    }

  }

</style>