import { createApp }from 'vue'
import App from './App.vue'
import router from './router/index'

/* Import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* Import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Import specific icons */
import { faMusic, faVideo, faCode, faPaintbrush, faChevronDown, faDownload, faGrip, faCircleArrowUp, faCircleArrowDown, faAsterisk, faMicrochip, faFeatherPointed, faParagraph } from '@fortawesome/free-solid-svg-icons'
import {faBandcamp, faYoutube, faGithub, faLinkedinIn, faFacebook} from "@fortawesome/free-brands-svg-icons";

/* Add icons to the library */
library.add(faMusic, faVideo, faCode, faPaintbrush, faChevronDown, faDownload, faBandcamp, faGrip, faCircleArrowUp, faCircleArrowDown, faYoutube, faGithub, faAsterisk, faMicrochip, faLinkedinIn, faFeatherPointed, faFacebook, faParagraph)


const app = createApp(App)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')